import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import TwoFAInput from "~/components/Inputs/TwoFA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendTwoFA } from "~/utils/Fetch/Lightning";
import Helpers from "~/config/helpers";
import { getLeadUUID, clearLeadUUID } from "~/utils/UUID";
import { useRouter } from "next/router";
import { genericFunnels } from "~/config/funnel";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";

const TwoFACode = (props) => {
  const {
    prevScreen,
    nextScreen,
    twoFAPhone,
    resubmit,
    displayError,
    twoFADisplayPhone,
    funnel,
  } = props;
  const router = useRouter();
  const [pin, setPin] = useState("");
  const pinLength = 6;
  const screenKey = "TwoFACode";
  const [loading, setLoading] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [error, setError] = useState(false);

  const style = {
    "--fa-primary-color": "rgba(234, 88, 12, 1)",
    "--fa-primary-opacity": 1,
  };

  const handleClick = () => {
    setLoading(true);

    resubmit(pin)
      .then((response) => {
        if (!response.ok && response.status === 401) {
          setLoading(false);
          setError(true);
          return { then: function () {} }; // Breaks promise chain without throwing an error
        }
        return response;
      })
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        localStorage.setItem("twofa", "complete");

        if (genericFunnels.includes(funnel)) {
          const leadUUID = getLeadUUID();
          clearLeadUUID();
          const referrer = localStorage.getItem("referrer") || "";
          if (process.env.NODE_ENV !== "production") {
            window.location.href = `http://localhost:8002/compare-agents/app?force_path=true&uuid=${leadUUID}&experience=tv&referrer=${referrer}`;
          } else {
            window.location.href = `/compare-agents/app?force_path=true&uuid=${leadUUID}&experience=tv&referrer=${referrer}`;
          }
        } else {
          nextScreen({ key: screenKey });
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        displayError();
      });
  };

  const handleResend = (e) => {
    e.preventDefault();
    let timeout = 1000;
    if (resendCount >= 1) {
      timeout *= resendCount / 2;

      setTimeout(() => {
        sendTwoFA(getLeadUUID(), twoFAPhone, true);
      }, timeout);
    } else {
      sendTwoFA(getLeadUUID(), twoFAPhone, true);
    }

    setResendCount(resendCount + 1);
  };

  return (
    <div className="MessageAgentForm__screen" key="2faCode">
      <div className="MessageAgentForm__screen-heading --alt">
        Verify your information
      </div>
      <div className="MessageAgentForm__screen-text --alt">
        <br />A message with a verification pin was just sent to{" "}
        {twoFADisplayPhone}.
      </div>
      <div className="MessageAgentForm__contact-info">
        <TwoFAInput
          pinLength={pinLength}
          className={"--full-width --center"}
          onChange={(e, value) => {
            setPin(value);
          }}
          error={error}
          setError={setError}
        />
      </div>
      <div className="MessageAgentForm__screen-text --alt">
        Didn’t receive a pin?{" "}
        <a href="#" onClick={handleResend}>
          Request again
        </a>
      </div>
      <div className="MessageAgentForm__contact-info"></div>
      <div className="MessageAgentForm__screen-controls">
        <button
          className="--alt"
          type="button"
          onClick={() => {
            prevScreen({ key: screenKey });
          }}
        >
          Back
        </button>
        <button
          type="button"
          disabled={loading || pin.length < pinLength}
          onClick={handleClick}
        >
          {loading && (
            <FontAwesomeIcon icon={faSpinnerThird} spin style={style} />
          )}
          Submit Pin
        </button>
      </div>
    </div>
  );
};

TwoFACode.propTypes = {
  nextScreen: PropTypes.func.isRequired,
  prevScreen: PropTypes.func.isRequired,
  screensSeen: PropTypes.object.isRequired,
  resubmit: PropTypes.func.isRequired,
  displayError: PropTypes.func.isRequired,
};

TwoFACode.defaultProps = {
  nextScreen: () => {},
  prevScreen: () => {},
  screensSeen: new Set(),
  resubmit: () => {},
  displayError: () => {},
};

export default TwoFACode;
