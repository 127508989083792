import App from "next/app";
import Router from "next/router";
import Head from "next/head";
import { parseCookies } from "nookies";

// Configs
import { wrapper } from "~/redux/configureStore";
import BugsnagInit from "~/components/Bugsnag";

import "~/styles/globals.css";
import "~/styles/app.scss";

const Bugsnag = BugsnagInit({
  releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
});

const ErrorBoundary = Bugsnag.getPlugin("react");

// Components
import Base from "./Base";

// Fontawesome handle server side rendering bug
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";

import {
  exportPageType,
  exportPageVersion,
  queryParams,
  trackPageView,
} from "../utils/Fetch/Analytics";
import { AgentSearchContextProvider } from "~/contexts/AgentSearchContext";
import { ToastProvider } from "react-toast-notifications";
import { ExperimentContextProvider } from "/contexts/ExperimentContext";

config.autoAddCss = false; /* eslint-disable import/first */

class MyApp extends App {
  constructor(props) {
    super(props);
    Router.events.on("routeChangeComplete", (url) => {
      if (
        url == "/agent/sign-up" ||
        !props.router.pathname.includes(
          "/[state_slug]/[city_slug]/[agent_slug]"
        )
      ) {
        const pageType = exportPageType(this.props.router.pathname);
        const pageVersion = exportPageVersion(this.props.router.pathname);
        const cookies = parseCookies();
        const session_uuid = cookies.rea_session_uuid;
        trackPageView({
          session_uuid,
          lead_uuid: cookies.lead_uuid,
          parameters: window && queryParams(),
          page: url,
          pageType,
          pageVersion,
        });
      }
    });
  }

  componentDidMount = () => {
    const { pageProps } = this.props;
    pageProps.canonical = process.env.CLIENT_ORIGIN;

    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.has("ee")) {
      localStorage.setItem("ee", urlSearchParams.get("ee"));
    }
    if (document.referrer === "") {
      localStorage.removeItem("referrer");
    } else if (!document.referrer.includes("realestateagents.com")) {
      localStorage.setItem("referrer", document.referrer);
    }
    if (
      !this.props.router.pathname.includes(
        "/[state_slug]/[city_slug]/[agent_slug]"
      ) &&
      this.props.router.state.route != "/404"
    ) {
      const pageType = exportPageType(this.props.router.pathname);
      const pageVersion = exportPageVersion(this.props.router.pathname);
      const cookies = parseCookies();
      const session_uuid = cookies.rea_session_uuid;
      trackPageView({
        session_uuid,
        lead_uuid: cookies.lead_uuid,
        parameters: window && queryParams(),
        statusCode: this.props.pageProps.statusCode,
        page: this.props.router.asPath,
        pageType,
        pageVersion,
      });
    }
  };

  render() {
    return (
      <ErrorBoundary>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
        <ToastProvider placement={"top-center"}>
          <AgentSearchContextProvider>
            <ExperimentContextProvider>
              <Base {...this.props} />
            </ExperimentContextProvider>
          </AgentSearchContextProvider>
        </ToastProvider>
      </ErrorBoundary>
    );
  }
}

export function reportWebVitals(metric) {
  if (process.env.LOG_CORE_WEB_VITALS === "true") {
    console.log(metric);
  }
}

export default wrapper.withRedux(MyApp);
