import Link from "next/link";
import UserCircleIcon from "@referralexchange/rea-ui-react/svgs/user-circle";

import useUser from "~/utils/useUser";
import { useEffect } from "react";

export default function UserMenu({}) {
  const { user } = useUser({ redirectIfFound: false });
  return (
    <>
      {user ? (
        user?.isLoggedIn && (
          <>
            <Link href="/logout">
              <div
                style={{
                  width: "24px",
                  height: "24px",
                  display: "inline-block",
                }}
              >
                <UserCircleIcon />
              </div>{" "}
              Sign out
            </Link>
          </>
        )
      ) : (
        <>
          <Link href="/sign-in">
            <div
              style={{ width: "24px", height: "24px", display: "inline-block" }}
            >
              <UserCircleIcon />
            </div>{" "}
            Sign in
          </Link>
        </>
      )}
    </>
  );
}
