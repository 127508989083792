import Link from "next/link";
import MainNavComponent from "@referralexchange/rea-ui-react/components/navigation/MainNav";
import Logo from "@referralexchange/rea-ui-react/svgs/rea-logo";

import BuyerMegaMenu from "./BuyerMegaMenu";
import SellerMegaMenu from "./SellerMegaMenu";
import AgentsMegaMenu from "./AgentsMegaMenu";
import AboutMegaMenu from "./AboutMegaMenu";

import SearchButton from "@referralexchange/rea-ui-react/components/SearchButton";

import PhoneIcon from "@referralexchange/rea-ui-react/svgs/phone";
import { useState } from "react";
import dynamic from "next/dynamic";

import UserMenu from "./UserMenu";

const LazyNavSearch = dynamic(
  () => import("~/components/organisms/NavSearch"),
  {
    ssr: false,
  }
);

export default function MainNav({ theme = null, ...childProps }) {
  const [openSearch, setOpenSearch] = useState(false);
  const logo = (
    <Link href="/">
      <div style={{ maxWidth: "280px", maxHeight: "55px" }}>
        <Logo />
      </div>
    </Link>
  );

  const main = (
    <>
      {!openSearch && (
        <>
          <BuyerMegaMenu />
          <SellerMegaMenu />
          <AgentsMegaMenu />
          <AboutMegaMenu />
          <SearchButton
            className={MainNavComponent.styles.hideMobile}
            onClick={() => setOpenSearch(!openSearch)}
            open={openSearch}
          />
        </>
      )}
      {openSearch && (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <LazyNavSearch />
        </div>
      )}
    </>
  );

  const aside = (
    <>
      {!openSearch && (
        <>
          <a href="tel:+18885862349">
            <div
              style={{ width: "24px", height: "24px", display: "inline-block" }}
            >
              <PhoneIcon />
            </div>{" "}
            (888) 586-2349
          </a>
          <UserMenu />
        </>
      )}
      {openSearch && (
        <div
          style={{ display: "flex", width: "100%", justifyContent: "start" }}
        >
          <SearchButton
            className={MainNavComponent.styles.hideMobile}
            onClick={() => setOpenSearch(!openSearch)}
            open={openSearch}
            theme={theme}
          />
        </div>
      )}
    </>
  );

  const search = (
    <SearchButton
      onClick={() => setOpenSearch(!openSearch)}
      open={openSearch}
      theme={theme}
    />
  );

  return (
    <MainNavComponent
      logo={logo}
      main={main}
      aside={aside}
      search={search}
      {...childProps}
      theme={theme}
      openSearch={openSearch && window.innerWidth <= 1196}
      setOpenSearch={setOpenSearch}
    />
  );
}
