import { css, StyleSheet } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../Form/Button";
import MessageAgentModal from "../Agents/MessageAgent/MessageAgentModal";
import { useEffect, useState } from "react";
import MessageAgentForm from "../Agents/MessageAgent/MessageAgentForm";
import { postEvent, resetSession } from "../Agents/MessageAgent/Analytics";
import { useRouter } from "next/router";

import { trackVirtualPageView } from "../../utils/Fetch/Analytics";

import { HOMEPAGE_FUNNEL, HOMEPAGE_TILE_FUNNEL } from "~/config/funnel";
import { faCirclePhone } from "@fortawesome/pro-duotone-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

const INITIAL_STEP = 1;
const HomepageHero = ({
  step,
  setStep,
  modalOpen,
  setModalOpen,
  selectedClientType,
}) => {
  const router = useRouter();
  const [confirmClose, setConfirmClose] = useState(false);
  const [largeModal, setLargeModal] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      setStep(INITIAL_STEP);
    }
  }, [modalOpen]);

  const currentFunnel = selectedClientType
    ? HOMEPAGE_TILE_FUNNEL
    : HOMEPAGE_FUNNEL;
  /**
   * onRequestClose
   *
   * Logic for displaying the confirm close screen
   *
   * @param {event} e
   * @param {bool} confirm
   */
  const onRequestClose = (e, confirm = false) => {
    if (step === INITIAL_STEP) {
      setConfirmClose(false);
      postEvent("message_agent_modal_closed", router, null, HOMEPAGE_FUNNEL);
      resetSession();
      setModalOpen(false);
    } else if (confirm) {
      setConfirmClose(true);
      postEvent(
        "message_agent_modal_exit_attempt",
        router,
        null,
        HOMEPAGE_FUNNEL
      );
    } else if (confirm === null) {
      setConfirmClose(false);
    } else {
      setModalOpen(false);
      postEvent("message_agent_modal_closed", router, null, HOMEPAGE_FUNNEL);
      resetSession();
      setTimeout(() => {
        setConfirmClose(false);
      }, 200);
    }
  };

  return (
    <div className={css(styles.homepageHeroContainer)}>
      <h1 className={css(styles.title)}>
        <span>Find the Best</span>
        <span>Real Estate Agent</span>
      </h1>
      {/* <p className={css(styles.innerText)}>
        Instantly see a personalized list of agents to choose from.
      </p> */}
      <div className={css(styles.searchContainer)}>
        <Button
          text="Find an Agent"
          buttonStyles={styles.buttonStyles}
          onClick={() => {
            setModalOpen(true);
            trackVirtualPageView("funnel_start", HOMEPAGE_FUNNEL);
          }}
        />
        <MessageAgentModal
          confirmClose={confirmClose}
          isOpen={modalOpen}
          largeModal={largeModal}
          onRequestClose={onRequestClose}
          agent={{}}
          funnel={currentFunnel}
          className={css(styles.msgAgent)}
        >
          <MessageAgentForm
            agent={{ prime: true }}
            funnel={currentFunnel}
            isClaimed={true}
            isOpen={modalOpen}
            afterNewScreen={setStep}
            step={step}
            onRequestClose={onRequestClose}
            onRequestLargeModal={setLargeModal}
            selectedClientType={selectedClientType}
          />
        </MessageAgentModal>
      </div>

      <div className={css(styles.bulletsWrapper)}>
        <div className={css(styles.questions)}>
          Questions? Call{" "}
          <a href="tel:8884910999" className={css(styles.phone)}>
            <FontAwesomeIcon icon={faCirclePhone} style={{ marginRight: 4 }} />
            (888) 491-0999
          </a>
        </div>
        <div className={css(styles.bulletsContainer)}>
          <span className={css(styles.bulletPoint)}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={css(styles.checkIcon)}
            />
            100% free
          </span>
          <span className={css(styles.bulletPoint, styles.secondBullet)}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={css(styles.checkIcon)}
            />
            Takes just 2 mins
          </span>
          <span className={css(styles.bulletPoint, styles.lastBullet)}>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className={css(styles.checkIcon)}
            />
            No commitment
          </span>
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  homepageHeroContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 100,

    "@media only screen and (min-width: 1024px)": {
      paddingTop: 64,
    },
  },
  innerText: {
    marginBottom: 30,
    fontSize: 18,
    color: "#fff",
    lineHeight: "28px",
    textAlign: "center",
  },
  buttonStyles: {
    fontSize: 20,
    boxShadow: "rgb(234 88 12 / 50%) 0px 10px 40px -10px",

    "@media only screen and (max-width: 767px)": {
      height: 60,
    },
  },
  questions: {
    fontSize: 16,
    color: "#fff",
    "@media only screen and (min-width: 1024px)": {
      display: "none",
    },
  },
  phone: {
    marginLeft: 5,
    // borderBottom: "1px solid #fff",
  },
  viewYourAgents: {
    marginTop: 16,
    textDecoration: "underline",
    color: "#fff",
    fontSize: 14,
  },
  title: {
    fontSize: 60,
    fontWeight: 700,
    lineHeight: "64px",
    textAlign: "center",
    color: "#fff",
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",

    "@media only screen and (max-width: 767px)": {
      fontSize: 36,
      lineHeight: "42px",
      marginBottom: 20,
    },
  },
  boxTitle: {
    fontSize: 32,
    marginBottom: 16,
  },
  bulletPoint: {
    fontSize: 14,
    fontWeight: 500,
    color: "#fff",
    marginRight: 20,
  },
  checkIcon: {
    marginRight: 8,
    background: "transparent",
  },
  secondBullet: {
    "@media only screen and (max-width: 553px)": {
      marginRight: 0,
    },
  },
  lastBullet: {
    marginRight: 0,

    "@media only screen and (max-width: 553px)": {
      marginTop: 4,
    },
  },
  bulletsWrapper: {
    textAlign: "center",
    "@media only screen and (max-width: 1023px)": {
      marginTop: 24,
    },
  },
  bulletsContainer: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",

    "@media only screen and (max-width: 1023px)": {
      marginTop: 12,
    },
  },
  // msgAgent: {
  //   maxWidth: "unset",
  //   width: "unset",
  // },
  buttonContainer: {
    marginBottom: 16,
  },
  box: {
    background: "#fff",
    padding: "32px 48px",
    borderRadius: 8,
  },
  searchContainer: {
    width: "50%",
    marginTop: 24,
    maxWidth: 350,
    height: 70,
    display: "flex",
    justifyContent: "center",
  },
});
export default HomepageHero;
