import { useRef, useState, useEffect, useCallback } from "react";
import Link from "next/link";
import { css, StyleSheet } from "aphrodite";
import { useRouter } from "next/router";

import { locationSearch } from "../utils/Fetch/Location";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "~/components/Form/Input";
import { capitalize } from "../utils/TextProcessing";

import { COLORS } from "../config/themes";
import { useAgentSearch } from "~/contexts/AgentSearchContext";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { faSearch as farSearch } from "@fortawesome/pro-regular-svg-icons";

const LocationSearch = ({
  inputStyles,
  noIcon = true,
  icon = farSearch,
  placesRef,
  autoFocus,
  buttonStyles,
  searchContainerStyles,
  inputPlaceholder,
  searchButton,
  iconSize,
  iconColor,
  type,
}) => {
  const dropdownRef = useRef(null);
  const router = useRouter();

  const locationSearchTimeout = useRef(null);
  const [locations, setLocations] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const {
    setZip,
    setStreetName,
    setStreetNumber,
    setCity,
    setState,
    setCountry,
    state,
    city,
    setLat,
    setLon,
    leadTypeInner,
    setMinPrice,
    setMaxPrice,
    minPrice,
    maxPrice,
    setLeadTypeInner,
    lat,
    lon,
    getQueryParams,
  } = useAgentSearch();

  useEffect(() => {
    const controller = new AbortController();
    if (inputValue === null || inputValue === "") {
      setLocations([]);
      if (dropdownIsOpen) {
        setDropdownIsOpen(false);
      }
    } else if (inputValue) {
      locationSearchTimeout.current = setTimeout(() => {
        locationSearch({
          location: inputValue,
          abortController: controller,
        })
          .then((resp) => {
            setLocations(resp);
            if (!dropdownIsOpen) {
              setDropdownIsOpen(true);
            }
          })
          .catch((err) => {
            if (err.name !== "AbortError") {
              throw err;
            }
          });
      }, 100);

      return () => {
        controller.abort("AbortSearch");
        clearTimeout(locationSearchTimeout.current);
      };
    }
  }, [inputValue]);

  const onSearchClick = useCallback(
    (e) => {
      if (!dropdownRef.current.contains(e.target)) {
        setDropdownIsOpen(false);
      }
    },
    [dropdownIsOpen]
  );

  useEffect(() => {
    if (dropdownIsOpen) {
      document.addEventListener("mousedown", onSearchClick);
      return () => {
        document.removeEventListener("mousedown", onSearchClick);
      };
    }
  }, [dropdownIsOpen]);

  useEffect(() => {
    reset();
  }, [router.asPath]);

  const onKeyPress = (e) => {
    if (locations.length) {
      if (e.key === "Enter") {
        setOpenSuggestions(false);
        reset();
      }
    }
  };

  const reset = () => {
    setLocations([]);
    setInputValue("");
    setDropdownIsOpen(false);
  };

  const onChange = (e) => {
    setInputValue(e.target.value);
  };

  const renderLocationSuggestions = () => {
    return locations.map((location, index) => {
      if (location) {
        const path = router.pathname;
        const inTopAgents = path.includes("/top-agents");
        const leadType = type === "buy" ? "buying" : "selling";
        return (
          <Link
            prefetch={false}
            onClick={() => {
              if (inTopAgents) {
                setCity(location.city);
                setState(location.state);
                setLat(location.lat);
                setLon(location.lon);
                setLeadTypeInner(leadType);
              }
            }}
            href={
              inTopAgents
                ? `/top-agents/${location.state.toLowerCase()}/${location.city
                    .replace(" ", "-")
                    .toLowerCase()}?lat=${location.lat}&lon=${
                    location.lon
                  }&lead_type=${leadType}&max_price=${maxPrice}&min_price=${minPrice}`
                : `/real-estate-agents/${location.city_slug}`
            }
            key={index}
          >
            <p className={css(styles.suggestion)} onClick={reset}>
              {`${capitalize(location.city.toLowerCase())}, ${location.state}`}
            </p>
          </Link>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={css(styles.dropdownContainer)} ref={dropdownRef}>
      <div className={css(styles.inputContainer, searchContainerStyles)}>
        <Input
          placeholder={
            inputPlaceholder || "What city are you buying or selling in?"
          }
          className={[styles.input, ...inputStyles]}
          autoComplete={"new-password"}
          fontAwesomeIcon={icon ? icon : farSearch}
          onKeyPress={onKeyPress}
          handleSelect={reset}
          type={"user-search-street-address"}
          forwardedRef={placesRef}
          noIcon={noIcon && !icon}
          iconSize={iconSize}
          iconColor={iconColor}
          autoFocus={autoFocus}
          onChange={onChange}
          value={inputValue}
          onFocus={() => {
            if (locations.length > 0) {
              setDropdownIsOpen(true);
            }
          }}
        />
        {searchButton ? (
          searchButton
        ) : (
          <button
            className={css(styles.button, buttonStyles)}
            aria-label={`Search button`}
          >
            <FontAwesomeIcon
              icon={faSearch}
              color={"#fff"}
              size="md"
              className={css(styles.searchIcon)}
            />
          </button>
        )}
      </div>
      <div className={css(styles.locationDropdown)}>
        {locations.length && dropdownIsOpen ? (
          <div className={css(styles.dropdown)}>
            {renderLocationSuggestions()}
          </div>
        ) : null}
      </div>
    </div>
  );
};
const styles = StyleSheet.create({
  dropdownContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  inputField: {
    width: "100%",
    marginRight: 0,
    height: "100%",
    fontSize: 14,
    // borderRadius: 4,
    border: 0,
    background: "#fff",
  },
  button: {
    padding: 16,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: 0,
    outline: "none",
    cursor: "pointer",
    background: COLORS.PRIMARY_ACTION(),
    position: "relative",
    borderRadius: 5,
  },
  inputContainer: {
    width: 500,
    marginRight: 0,
    height: 50,
    zIndex: 3,
    boxSizing: "border-box",
    position: "relative",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    background: "#fff",
    padding: 8,
    boxSizing: "border-box",

    "@media only screen and (min-width: 320px)": {
      width: "100%",
    },

    "@media only screen and (min-width: 1024px)": {
      width: 500,
    },
  },
  searchContainer: {
    minWidth: 0,
    width: "100%",
    boxSizing: "border-box",
    height: "100%",
  },
  searchIcon: {
    zIndex: 2,
    transform: "translate(-50%, -50%)",
    position: "absolute",
    top: "50%",
    left: "50%",
  },
  locationDropdown: {
    width: "100%",
    boxShadow: "3px 3px 10px 1px #eee",
    background: "#fff",
    position: "absolute",
    borderRadius: "5px",
    marginTop: "-5px",
    marginLeft: 0,
    color: "#000",
    zIndex: 2,
    textAlign: "left",
    maxHeight: 360,
    overflowY: "scroll",

    "::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: 7,
      marginRight: 1,
    },
    "::-webkit-scrollbar-thumb": {
      borderRadius: 4,
      backgroundColor: "rgba(0, 0, 0, .5)",
      "-webkit-box-shadow": "0 0 1px rgba(255, 255, 255, .5)",
    },

    "@media only screen and (max-width: 767px)": {
      maxHeight: 300,
    },
  },
  suggestion: {
    padding: 20,
    cursor: "pointer",
    color: COLORS.BLACK(),
    textDecoration: "none",

    ":hover": {
      background: COLORS.GREY(1),
    },

    "@media only screen and (max-width: 767px)": {
      padding: 16,

      ":hover": {
        background: "#fff",
      },
    },
  },
  dropdown: {
    padding: 20,

    "@media only screen and (max-width: 767px)": {
      padding: 16,
    },
  },
});
export default LocationSearch;
