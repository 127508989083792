export const MAI_flavorText = {
  buyer: {
    days_on_market_median_score:
      "The average home has been on the market a long time",
    mm_inventory_chg_score: "More homes are being listed than sold",
    mm_price_cut_chg_score:
      "Homes are reducing their prices more quickly this month",
    months_inventory_score:
      "At the current rate of sales it will take a long time for existing houses to sell",
    price_cut_pct_score: "Many homes have reduced their offer prices",
    yy_new_listings_score:
      "More sellers are listing their homes compared to last year",
    yy_price_change_score:
      "The median prices are lower this year compared to last year",
  },
  seller: {
    days_on_market_median_score:
      "The average home has been on the market a short time",
    mm_inventory_chg_score: "More homes are selling than coming on the market",
    mm_price_cut_chg_score:
      "Homes are not reducing their prices more quickly this month",
    months_inventory_score:
      "Houses are selling quickly compared to the amount on the market",
    price_cut_pct_score: "Fewer homes have reduced their offer prices",
    yy_new_listings_score:
      "Fewer sellers are listing their homes compared to last year",
    yy_price_change_score: "The median prices are rising year over year",
  },
};

export const AWARD_KEYS = [
  "price_avg_rnk",
  "listings_all_rnk",
  "sold_not_reduced_rate_rnk",
  "price_reduced_rate_avg_rnk",
  "sold_days_avg_rnk",
  "days_on_market_median_rnk",
  "agent_score",
];

export const US_STATE_HASH_SHORT_TO_LONG = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const US_STATE_HASH_LONG_TO_SHORT = {
  Alabama: "AL",
  Alaska: "AK",
  "American Samoa": "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  "Federated States Of Micronesia": "FM",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  "Marshall Islands": "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "MP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  "Virgin Islands": "VI",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const MAJOR_CITIES = [
  { city: "Atlanta", state: "GA" },
  { city: "Miami", state: "FL" },
  { city: "Boston", state: "MA" },
  { city: "Portland", state: "OR" },
  { city: "Denver", state: "CO" },
  { city: "San Francisco", state: "CA" },
  { city: "Houston", state: "TX" },
  { city: "Seattle", state: "WA" },
  { city: "New York", state: "NY" },
  { city: "Las Vegas", state: "NV" },
  { city: "Los Angeles", state: "CA" },
  { city: "Chicago", state: "IL" },
  { city: "Orlando", state: "FL" },
  { city: "Austin", state: "TX" },
  { city: "Buffalo", state: "NY" },
  { city: "Dallas", state: "TX" },
];

export const CAROUSEL_AGENT_IDS = [
  "5775",
  "7980",
  "10457",
  "15900",
  "24893",
  "31955",
  "40791",
  "45317",
  "85921",
  "94812",
  "95574",
  "95746",
  "96566",
  "99826",
  "103098",
  "106657",
  "111567",
  "117351",
  "121205",
  "144049",
  "145554",
  "148241",
  "167865",
  "169064",
  "169651",
];

export const TESTIMONIAL_SEEDS = [
  {
    review:
      "The service was great and the recommendation of the realtor was fantastic. The realtor was fair and honest he gave me a very reasonable selling price and follow the listing all the way through. The house sold within the first week.",
    name: "Rita V.",
    location: "Tennessee",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "green",
  },
  {
    review:
      "I used the website to identify potential agents in the new city I was moving too. I ultimately went with one of the recommended agents and had a very smooth home buying process.",
    name: "Alexis C.",
    location: "Iowa",
    review_type: "Bought a home using RealEstateAgents.com",
    circleColor: "blue",
  },
  {
    review:
      "My realtor was very professional in every aspect of the sale. My house sold within a week. She called or texted me through out the whole process.",
    name: "Ruth J.",
    location: "Ohio",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "eggshell",
  },
  {
    review:
      "We found an excellent Realtor, who guided us through the sale and did an excellent job along the way.",
    name: "Steve M.",
    location: "Florida",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "green",
  },
  {
    review: "Very professional, we had a great experience.",
    name: "Jon B.",
    location: "Wisconsin",
    review_type: "Bought a home using RealEstateAgents.com",
    circleColor: "blue",
  },
  {
    review:
      "The agent you recommended was fantastic. He stayed in touch throughout the process. He kept us informed every step of the way. The sale was quick and couldn't have gone smoother. Would definitely recommend to anyone else and will definitely use in the future if needed.",
    name: "Stanley K.",
    location: "Indiana",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "eggshell",
  },

  {
    review: "Great realtors. My home sold in five days!",
    name: "Joe L.",
    location: "Illinois",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "green",
  },
  {
    review:
      "My selected agent was very thorough, professional, and attentive to my needs. He was absolutely the BEST!",
    name: "Bonnie W.",
    location: "Indiana",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "blue",
  },
  {
    review: "Had issues with the title company, which agents handled.",
    name: "John S.",
    location: "Arizona",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "eggshell",
  },
  {
    review:
      "We had a very unique situation... My sister and I live in different states and the house belonged to our deceased Mother... Service was great could not have been any better...",
    name: "Joe H.",
    location: "Texas",
    review_type: "Sold a home using RealEstateAgents.com",
    circleColor: "green",
  },
];

export const BUSINESS_HIGHLIGHT_ICONS = {
  "Boutique brokerage": "/static/boutique-brokerage.png",
  "[LICENSE YEARS] years in business": "/static/years-in-business.png",
  "Free consultations": "/static/free-consultations.png",
  "Locally owned & operated": "/static/locally-owned-operated.png",
  "REALTOR® certified": "/static/realtor-certified.png",
  "Custom emailed listings": "/static/custom-emailed-listings.png",
  "3D home walkthroughs": "/static/3d-home-walkthroughs.png",
  "Tour via video chat": "/static/tour-via-video-chat.png",
  "Discounts available": "/static/discounts-available.png",
  "Satisfaction guaranteed": "/static/satisfaction-guaranteed.png",
  "Offers customized solutions": "/static/offers-customized-solutions.png",
  "References available": "/static/references-available.png",
  "Free home valuations": "/static/free-consultations.png",
  "Custom home marketing": "/static/custom-home-marketing.png",
  "Luxury home specialist": "/static/luxury-specialist.png",
  "Available by appointment": "/static/available-by-appointment.png",
  "First time buyers": "/static/first-time-buyers.png",
  "Relocation specialist": "/static/relocation-specialist.png",
  "24/7 availability": "/static/24-7-availability.png",
  "Women-owned & operated": "/static/women-owned-operated.png",
};

export const ARTICLES = [
  {
    title: "An Overview of Farm Land, Crops and Property During the Dust Bowl",
    slug: "farms-crops-property-dust-bowl",
  },
  {
    title: "Helping the Homeless: Ways to Get Involved",
    slug: "helping-the-homeless",
  },
  {
    title: "Real Estate History: All About the Louisiana Purchase",
    slug: "real-estate-history-louisiana-purchase",
  },
  {
    title: "House History & Genealogy",
    slug: "house-history-genealogy",
  },
  {
    title: "A Family's Guide to Recycling at Home",
    slug: "recycling-at-home",
  },
  {
    title: "History of American Landownership and Sharecropping",
    slug: "history-american-landownership-sharecropping",
  },
  {
    title: "Indoor and Outdoor Gardening for Your Home",
    slug: "indoor-outdoor-gardening",
  },
  {
    title: "Native American History for Kids: Homes and Dwellings",
    slug: "native-american-history-kids-homes-dwellings",
  },
  {
    title: "All About the Ernest Hemingway House",
    slug: "all-about-the-ernest-hemingway-house",
  },
  {
    title: "Flipping a House 101",
    slug: "flipping-a-house-101",
  },
  {
    title: "Urban Planning Optimization: Homelessness",
    slug: "urban-planning-optimization-homelessness",
  },
  {
    title: "Planning a Move: A Guide to Packing, Organizing and Donating",
    slug: "planning-a-move-guide",
  },
  {
    title: "A Homeowner's Resource Guide to Gardening",
    slug: "homeowners-resource-guide-to-gardening",
  },
  {
    title: "Home Modifications for Wounded Warriors",
    slug: "home-modifications-for-wounded-warriors",
  },
  {
    title: "An Accessibility Checklist to Modify Your Home",
    slug: "accessibility-checklist-to-modify-home",
  },
  {
    title: "European Architecture: Castle Terminology",
    slug: "european-architecture-castle-terminology",
  },
  {
    title: "Ways to Protect Your Land Rights",
    slug: "ways-to-protect-your-land-rights",
  },
  {
    title: "Building a Home: Important Preparation Steps",
    slug: "building-a-home-important-prep-steps",
  },
  {
    title: "Home Modifications for the Visually Impaired and Blind",
    slug: "home-modifications-visually-impaired-and-blind",
  },
  {
    title: "GIS and Land Surveying Resources",
    slug: "gis-and-land-surveying-resources",
  },
];

export const BLOG_POSTS = [
  {
    title:
      "'May I refer you to my assistant?' Kids learn the family business during pandemic",
    slug:
      "https://www.inman.com/2020/07/31/may-i-refer-you-to-my-assistant-kids-learn-the-family-business-during-pandemic/",
  },
  {
    title: "Guilt-free parenting? 3 moms share how they found their stride",
    slug:
      "https://www.inman.com/2019/08/12/guilt-free-parenting-3-moms-share-how-they-found-balance/",
  },
  {
    title:
      "Casual Conversations | The Power of Inspirational Leadership with Amber Martinez, ReferralExchange",
    slug: "https://www.youtube.com/watch?v=REC3VAwF4VI",
  },
];

export const FAQ_CONTENT = [
  {
    question: "Why should I use an agent to buy a home?",
    slug: "for-buyers",
    answers: [
      <p>
        A local real estate agent is an expert who can help you immensely when
        purchasing a home. They can help you understand your needs, guide your
        search for a dream home, and make effective offers to get you the best
        outcome.
      </p>,
    ],
  },
  {
    question: "How do I choose an agent to help me buy a home?",
    slug: "for-buyers",
    answers: [
      <p>
        When you work with an agent you want someone that has great skills and
        expertise, but also that you feel comfortable working with. A great
        buying agent will know your local property market inside and out and
        will be attuned to your specific needs and budget. They will also be an
        excellent negotiator and guide you through the entire process of
        home-buying. However, a great agent for you will also be someone you
        feel comfortable being around and enjoy working with, so it’s important
        to screen agents both for their expertise and for how well you “click”
        with one another
      </p>,
    ],
  },
  {
    question: "How much does a buying agent typically get paid?",
    slug: "for-buyers",
    answers: [
      <p>
        A top buying agent typically earns somewhere between 2%-3% of the
        transaction’s final sale price. Agent commission rates can vary
        regionally and are calculated based on the property’s closing price.
        Note that the best real estate agents should be able to clearly explain
        the value they bring to the table.
      </p>,
    ],
  },
  {
    question: "Can I work with more than one agent?",
    slug: "for-buyers",
    answers: [
      <p>
        Yes, up to a point. As a buyer, you are welcome to communicate with
        multiple agents at once and see different properties with multiple
        agents. However once you see a specific house with an agent (or submit
        an offer on a specific property), you may then be obligated to continue
        working with that agent on that specific property. Many real estate
        agents may attempt to establish “agency” with you, meaning they’re the
        only agent you’re currently working with.
      </p>,
    ],
  },
  {
    question: "Can I just work with the agent who has the home listed?",
    slug: "for-buyers",
    answers: [
      <p>
        Yes, however while you can legally have the listing agent represent you
        as the buyer (in the U.S.) some buyers are wary of this practice, as the
        agent you work with should always have <strong>your</strong> best
        interests in mind. Working with the listing agent to buy the property
        can create a conflict of interest (e.g. during price negotiation) so it
        may not be in your best interests to do so.
      </p>,
    ],
  },
  {
    question: "What should I have ready before meeting with a buyer's agent?",
    slug: "for-buyers",
    answers: [
      <p>
        It depends. You can expect the agent you’re meeting with to advise you
        on specifics given your situation. Some common items that may be helpful
        are a pre-qualification or pre-approval letter from your bank, a list of
        the most important things you’re looking for in a property, and a
        general sense of the neighborhoods and locations you’re interested in.
        This will give the agent you’re speaking with some initial context to
        start advising you.
      </p>,
    ],
  },
  {
    question: "Should I talk to a lender first or an agent first?",
    slug: "for-buyers",
    answers: [
      <p>
        It can be advantageous to connect with a REALTOR® or agent prior to
        speaking with a lender, as the best real estate agents can guide you
        through the entire real estate transaction process while a lender only
        focuses on your financial needs.
      </p>,
    ],
  },
  {
    question: "What is the MLS? Why is it important when buying a home?",
    slug: "for-buyers",
    answers: [
      <p>
        MLS is short for <strong>"Multiple Listing Service"</strong>. This
        service provides real estate agents with details about a property and
        any transactions that may occur. The MLS is typically only available to
        real estate industry professionals.
      </p>,
    ],
  },
  {
    question:
      "At what point in the process should I talk to the agent? Should I find a home I’m interested in first?",
    slug: "for-buyers",
    answers: [
      <p>
        There’s no wrong time to get connected with the best local real estate
        agents or to begin comparing REALTORS®. Once you have identified a good
        real estate agent you’re happy working with, they can support you with
        all other aspects of your buying journey including finding a home,
        understanding loan options, and submitting an offer.
      </p>,
    ],
  },
  {
    question: "How long does the home buying process usually take?",
    slug: "for-buyers",
    answers: [
      <p>
        Every home purchase is different and depends on what’s being included in
        the “process”. Once an offer is accepted by a home seller until the
        closing date takes approximately 30-45 days (on average), although this
        can be a lot less depending on the context. If you include getting
        pre-approved, searching for and touring homes, and the entire escrow
        process, a top REALTOR® can guide you through this process in 3-6 months
        typically.
      </p>,
    ],
  },
  {
    question: "Who pays the commission when buying a home?",
    slug: "for-buyers",
    answers: [
      <p>
        The most common practice is for the seller to pay out both the selling
        and buying agent commissions (though exceptions always exist). Working
        with a top buyer’s real estate agent means they’ll always put your best
        interest first.
      </p>,
    ],
  },
  {
    question:
      "What is a short sale? What is a foreclosure? What is the difference?",
    slug: "for-buyers",
    answers: [
      <p>
        A <strong>short sale</strong> is when a home is being sold for less than
        what is still owed on the mortgage by the current owner. A{" "}
        <strong>foreclosure happens</strong> when a borrower defaults (stops
        making payments) on their loan and the bank/lender takes over the
        property and attempts to recoup their loss through a sale.
      </p>,
    ],
  },
  {
    question: "What does contingent mean?",
    slug: "for-buyers",
    answers: [
      <p>
        A property or transaction is <strong>contingent</strong> when an offer
        to purchase has been accepted and is moving forward, however there is an
        additional condition that is waiting to be satisfied before the
        transaction can proceed further (thus the transaction is contingent upon
        that condition being met).
      </p>,
    ],
  },
  {
    question: "What are closing costs?",
    slug: "for-buyers",
    answers: [
      <p>
        <strong>Closing costs</strong> are additional costs that exist for a
        home purchase that develop beyond the costs of the home’s listing price.
        These can include escrow fees, lender fees, taxes, and more. While
        amounts vary across states and transactions, average total closing costs
        are estimated at between 2% - 6% of the property’s purchase price. These
        costs must be paid at closing in order for the transaction to close.
      </p>,
    ],
  },
  {
    question:
      "Why do certain types of loans have extra stipulations for the property (ex: VA, FHA)?",
    slug: "for-buyers",
    answers: [
      <p>
        Every loan has repayment conditions or stipulations attached to it, such
        as the amount of interest incurred and the loan’s term. Some loans
        through government agencies or other large institutions may have
        additional qualifying criteria or conditions related to repayment, for
        example, a Veterans Affairs (VA) or Federal Housing Administration (FHA)
        Loan.
      </p>,
    ],
  },
  {
    question: "What if I change my mind about the house I wanted to buy?",
    slug: "for-buyers",
    answers: [
      <p>
        Depending on where you are in the home buying process, your options will
        be very different. If still in the early stages of comparing
        neighborhoods and homes, there are no consequences to changing what
        you’re looking for. If you are further along in the process and have
        already submitted an offer on a home that has been accepted, then you
        may have to forfeit the earnest money deposit you initially put forward
        on the home, which is typically at least 1% and often up to 3% of the
        listing price.
      </p>,
    ],
  },
  {
    question: "What are clouds on a title?",
    slug: "for-buyers",
    answers: [
      <p>
        A <strong>clouded title</strong> means there are additional claims
        toward a title (or claims of additional ownership). This means that
        there is at least one (and possibly more) parties involved that are
        claiming some type of ownership or stake in the property. This clouded
        title can often delay closing, with the possibility of a protracted
        title dispute occurring.
      </p>,
    ],
  },
  {
    question: "What does a home inspector do? What things do they look for?",
    slug: "for-buyers",
    answers: [
      <p>
        A <strong>home inspector</strong> completes an in-person inspection of a
        property to assess its current condition. This can be done for multiple
        reasons, including to help the buyer better understand the home they are
        buying or to satisfy an inspection contingency. While there are many
        specifics that are covered by a professional home inspector, some of the
        major items include a home’s roof, foundation, electrical system,
        fireplace, and more.
      </p>,
    ],
  },
  {
    question: "Why should I use an agent to sell my home?",
    slug: "for-sellers",
    answers: [
      <p>
        There are many benefits to using an agent for selling your home. The top
        real estate agents can guide you on setting an appropriate listing
        price, list your home on the local MLS, market it to other real estate
        professionals, and negotiate receiving the best offer. According to the{" "}
        <a
          target={"_blank"}
          href={
            "https://www.nar.realtor/research-and-statistics/quick-real-estate-statistics"
          }
        >
          National Association of REALTORS
        </a>
        , sellers who use an agent sell their homes for $105,000 more than those
        that don’t!
      </p>,
    ],
  },
  {
    question: "How should I choose an agent to list and sell my home?",
    slug: "for-sellers",
    answers: [
      <p>
        Request a range of proposals. Interview the agents for local expertise
        and degree of personality fit. Check their listing price recommendations
        and sale price estimates using publicly available Automated Valuation
        Models (AVMs). Identify the agent you like most and negotiate their fee
        using information from the other proposals you received. Make sure you
        talk to your agent about things like whether you could vacate the
        property before it gets listed, and staging options.
      </p>,
    ],
  },
  {
    question: "How much does an agent typically get paid?",
    slug: "for-sellers",
    answers: [
      <p>
        When you sign a listing contract with your agent you’ll typically agree
        on what you’ll pay the agent when they help sell your home successfully.
        Fees typically range from 2-3% of the sale price for the listing agent
        and 2-3% of the sale price for the buying agent, although the exact
        structure and fees will vary from market to market. We recommend getting
        proposals from a range of agents before committing to one in particular.
        RealEstateAgents.com makes it easy to get proposals from multiple
        heavily-vetted and hand-picked agents, just click{" "}
        <a href={"https://www.realestateagents.com/sell"}>here</a> to get
        started now.
      </p>,
    ],
  },
  {
    question: "What does FSBO mean?",
    slug: "for-sellers",
    answers: [
      <p>
        <strong>For Sale By Owner</strong>, or FSBO for short, is a type of
        transaction where a homeowner attempts to sell their home themselves
        without the aid of a real estate agent. While this is often done as a
        cost savings mechanism, it can often lead to other challenges as the
        average person lacks the comprehensive understanding of the real estate
        transaction process held by a top agent. Additionally, it can leave you
        legally liable for any potential missteps, which may ultimately cost the
        seller much more than standard agent fees.
      </p>,
    ],
  },
  {
    question: "What is a 1031 exchange?",
    slug: "for-sellers",
    answers: [
      <p>
        A <strong>1031 exchange</strong> is a type of tax transaction that
        allows a party to “swap” an existing investment property with a newly
        acquired property, allowing the party to then defer the capital gains
        tax paid.
      </p>,
    ],
  },
  {
    question: "Can a real estate agent help me sell a mobile home?",
    slug: "for-sellers",
    answers: [
      <p>
        A top real estate agent can help you sell a mobile or{" "}
        <strong>manufactured home</strong>.
      </p>,
    ],
  },
  {
    question: "How long does it take to sell a home?",
    slug: "for-sellers",
    answers: [
      <p>
        The timeline of every transaction is different and will vary based on a
        variety of factors, including market conditions, location, price, and
        more. According to the{" "}
        <a
          target={"_blank"}
          href={
            "https://www.nar.realtor/research-and-statistics/quick-real-estate-statistics"
          }
        >
          National Association of REALTORS
        </a>
        , in 2022 the typical home sold was only listed on the market for two
        weeks. Including any preparation that needs to be taken prior to
        listing, and the average escrow period of 45 days, you can expect a home
        sale from start to finish to take anywhere from 2 - 4 months.
      </p>,
    ],
  },
  {
    question: "If I’m buying and selling, should I buy or sell first?",
    slug: "for-sellers",
    answers: [
      <p>
        This consideration varies for everyone and can depend heavily on your
        personal situation and financial capacity. Discussing your options
        closely with your agent and mortgage provider can help inform you of
        your options.
      </p>,
    ],
  },
  {
    question: "When is the best time to sell my home?",
    slug: "for-sellers",
    answers: [
      <p>
        The real estate market is always changing and follows typical trends of
        picking up in the spring, peaking during summer, cooling off in the
        fall, and slowing during winter. A top real estate agent will be able to
        offer you strategic advice about the timing of your home sale to get you
        the best possible outcome.
      </p>,
    ],
  },
  {
    question: "How should I prepare my home before it goes on the market?",
    slug: "for-sellers",
    answers: [
      <p>
        Preparing your home before it goes on the market can include everything
        from cosmetic fixes to major repairs. Before going on the market, your
        REALTOR should advise you on which items should be completed prior to
        the listing going live. Some best practices include cleaning the yard of
        any major debris, cleaning the home’s interior (kitchen, bathrooms,
        floors, etc.) and the exterior (windows, doors). A great agent will also
        advise you on whether it’d be best for you to move out of your house
        before selling it, and whether you should get it staged.
      </p>,
    ],
  },
  {
    question: "What is an appraisal?",
    slug: "for-sellers",
    answers: [
      <p>
        An <strong>appraisal</strong> is a process where a professional home
        appraiser visits and evaluates a home in order to determine its price.
        An appraisal is often a necessary step when selling a home in order for
        the buyer’s loan to be funded and approved.
      </p>,
    ],
  },
  {
    question: "Am I able to change listing agents?",
    slug: "for-sellers",
    answers: [
      <p>
        This will depend on the specific terms of your listing agreement with
        the agent and on when the listing agreement is scheduled to terminate.
        Even if you are eligible to have the listing agreement terminated, the
        new agent you list with might be responsible for paying your previous
        agent a referral fee.
      </p>,
    ],
  },
  {
    question: "What is a title search?",
    slug: "for-sellers",
    answers: [
      <p>
        A title search is a process where a search is completed to determine if
        there are any parties (in addition to the principal who is selling) that
        may have a claim, or lien, on a property. If other parties are
        determined to have a valid claim, then the title is said to be
        “clouded”. If no such parties exist, the title is determined to be
        clean.
      </p>,
    ],
  },
  {
    question: "How will an agent market my home?",
    slug: "for-sellers",
    answers: [
      <p>
        Every agent has their own unique approach, and the best real estate
        agents will take a creative approach to marketing your home, but there
        are a variety of ways to market a property. This can include some or all
        of listing it on the MLS, posting the property on online listing
        websites, featuring the listing in their client newsletters, informing
        other agents in their brokerage about your listing - and more!
      </p>,
    ],
  },
  {
    question: "Why do I need to sign a listing contract?",
    slug: "for-sellers",
    answers: [
      <p>
        A <strong>listing agreement</strong> is a contract that states the terms
        and conditions related to the sale of your property. It should include
        details like how much commission is paid, how the property might be
        marketed, and what your rights are as the property owner. This agreement
        also grants agency to the listing agent, meaning they have a fiduciary
        responsibility to act in your best interest.
      </p>,
    ],
  },
  {
    question: "How does an agent decide the listing price of a property?",
    slug: "for-sellers",
    answers: [
      <p>
        The most common way to determine a list price is to look at comparable
        listings (or “comps”) in the neighborhood and surrounding area that have
        sold or listed recently. Based on this market activity of similar
        properties, your agent will recommend an appropriate list price,
        although ultimately the price to list your home at is your decision.
      </p>,
    ],
  },
  {
    question: "How do I join RealEstateAgents.com?",
    slug: "for-agents",
    answers: [
      <p>
        It’s easy and free! Simply{" "}
        <a href={"/agent/sign-up"}>enroll here to create your profile</a> and
        become eligible to receive exclusive client referrals.
      </p>,
    ],
  },
  {
    question: "What are the benefits of joining RealEstateAgents.com?",
    slug: "for-agents",
    answers: [
      <p>
        You get an exclusive, customizable web profile to attract new clients
        and grow your business.
      </p>,
      <p>
        Once you set up your profile, clients can reach out directly to you on
        RealEstateAgents.com and you only pay a referral fee upon closing which
        means there are no up-front costs as with many other platforms.
      </p>,
      <p>
        There are countless benefits to joining RealEstateAgents.com! This all
        new marketplace is the hottest resource where clients are going to find
        the best real estate agents. You can set up a custom profile that
        includes client reviews, badges, a photo, video, your service area, and
        more! You’ll also gain access to our Personal Relocation Network,
        allowing you to submit referrals for clients across the country. Join
        today to begin receiving referrals and expand your business!
      </p>,
    ],
  },
  {
    question: "Is there a cost to sign up with RealEstateAgents.com?",
    slug: "for-agents",
    answers: [
      <p>
        It’s 100% free to sign up with RealEstateAgents.com! You can either
        claim or create a profile all for free - no payment or credit card
        information will be collected.
      </p>,
    ],
  },
  {
    question: "How many referrals can I expect?",
    slug: "for-agents",
    answers: [
      <p>
        The number of referrals received depends on a number of factors,
        including current market conditions, seasonality, and your specific
        location. You can put yourself in the best possible position to receive
        more referrals by filling out your RealEstateAgents.com profile page as
        fully as possible to attract the interest of more clients. And if you’re
        interested in augmenting your business with even more referrals, talk to
        one of our customer representatives about our PRIME subscription
        service.
      </p>,
    ],
  },
  {
    question:
      "Are these referrals qualified? What is your qualification process?",
    slug: "for-agents",
    answers: [
      <p>
        All our referrals go through a number of qualifying steps before being
        sent to our partner agents. Clients must complete a multi-step
        questionnaire to verify their intent and complete a phone-based two
        factor authentication to verify their phone number. Following these
        steps, we take a tech forward approach to verify the client’s name,
        phone number, and email address before matching them with an agent. In
        cases where we need to we will also speak to the client directly to
        validate their information and make sure we fully understand their
        needs.
      </p>,
    ],
  },
  {
    question: "Where do the referrals come from?",
    slug: "for-agents",
    answers: [
      <p>
        A majority of our referrals are initiated by clients themselves. They
        request to be connected with one or multiple real estate agents.
        Approximately 5% of our referrals are submitted by other agents through
        our Personal Relocation Network. In order to submit a client into this
        network, the agent must verify it is a personal referral and the client
        has granted their permission to be referred to other agents.
      </p>,
    ],
  },
  {
    question: "Can I refer clients to other agents on your website?",
    slug: "for-agents",
    answers: [
      <p>
        Yes! Our Personal Relocation Network is the largest in the country,
        featuring over 160,000 agents and coverage in all U.S. zip codes and
        Canadian post codes! Begin submitting referrals today to earn a referral
        fee and grow your business.
      </p>,
    ],
  },
  {
    question: "What’s the cost of submitting referrals?",
    slug: "for-agents",
    answers: [
      <p>
        There are no costs to submit a referral on RealEstateAgents.com. You can
        submit up to 10 referrals per month for free. This number may change in
        the future depending on your past submitted referral’s success.
      </p>,
    ],
  },
  {
    question: "How much am I paid when a referral I submit closes?",
    slug: "for-agents",
    answers: [
      <p>
        You will receive a 25% referral fee when a referral you submit closes.
        When a closing takes place, a 30% referral fee is paid to
        RealEstateAgents.com. We keep 5% and pass the remaining 25% back to you
        as the referral’s submitting agent.
      </p>,
    ],
  },
  {
    question: "How are agents matched with my client?",
    slug: "for-agents",
    answers: [
      <p>
        Our AI-powered system does a great job at matching agents who are a good
        fit based on a client’s needs and market. We recommend you speak with
        your submitted client about how to best support them in selecting an
        agent from the provided list.
      </p>,
    ],
  },
  {
    question: "What’s a REALTOR®?",
    slug: "working-with-an-agent",
    answers: [
      <p>
        A REALTOR® is a real estate professional that is part of the{" "}
        <a target="_blank" href={"https://www.nar.realtor/"}>
          National Association of Realtors
        </a>
        , America's largest trade organization. The REALTOR® designation means
        an agent is a member of this collective and they identify as a real
        estate professional who subscribes to NAR’s strict{" "}
        <a
          target="_blank"
          href={
            "https://www.nar.realtor/about-nar/governing-documents/the-code-of-ethics"
          }
        >
          Code of Ethics
        </a>
        . REALTORS® have access to unique tools and networks that advance the
        entire industry and their service to clients.
      </p>,
    ],
  },
  {
    question:
      "What’s the difference between an agent and a broker? Is one better than the other?",
    slug: "working-with-an-agent",
    answers: [
      <p>
        A <strong>real estate agent</strong> is the representative who will most
        likely work with you to prepare and list or buy your property, guiding
        you through the transaction process. They are licensed professionals and
        must hang their license at a brokerage in order to complete
        transactions.
      </p>,
      <p>
        A <strong>real estate broker</strong> is often behind the scenes working
        to support a real estate agent, managing the brokerage as a whole and
        employing salespeople (agents) to work your transaction. They must hold
        a broker’s license. If selling a home, your property is actually listed
        through the brokerage, with the agent working on the broker’s behalf.
      </p>,
    ],
  },
  {
    question: "How do agents get paid?",
    slug: "working-with-an-agent",
    answers: [
      <p>
        Agents are paid by the seller of a real estate transaction. Their pay
        (both the selling and buying agents) is usually a commission percentage
        calculated off of the property’s sale price. The agents’ commission is
        paid at the close of escrow.
      </p>,
    ],
  },
  {
    question: "What is a dual agent?",
    slug: "working-with-an-agent",
    answers: [
      <p>
        A <strong>dual agent</strong> is an agent that represents both the
        seller and buyer in a single transaction. This is also often referred to
        as representing “both sides” of a transaction.
      </p>,
    ],
  },
  {
    question: "I want a local agent, not one from a big national company.",
    slug: "working-with-an-agent",
    answers: [
      <p>
        RealEstateAgents.com features the best local agents, no matter where
        you’re located! From boutique regional brokerages to large national
        brands, all our agents are experts in their local markets, understanding
        what makes each neighborhood unique and the community’s best features.
      </p>,
    ],
  },

  {
    question: "How does RealEstateAgents.com work for Buyers and Sellers?",
    slug: "how-we-work",
    answers: [
      <p>
        RealEstateAgents.com is a brokerage that works in all 50 states to
        connect buyers and sellers with the best real estate agents in their
        local market. By creating cutting-edge agent profiles, we allow clients
        to compare an agent based on their past performance and unique
        qualifications.
      </p>,
      <p>
        If you find an agent you like, you can initiate contact with them and
        begin moving your transaction forward. If you aren’t sure where to
        start, you can answer a few questions and we’ll provide you with a
        custom list of top agents in your area to work with. Best of all, every
        element is free for clients to use!
      </p>,
      <p>
        RealEstateAgents.com also offers a{" "}
        <a href={"https://www.realestateagents.com/blog"}>blog</a> that’s
        updated weekly with articles written by industry experts to support you
        whether you’re buying, selling, or looking to learn more about the
        industry as a whole.
      </p>,
    ],
  },
  {
    question: "How does RealEstateAgents.com vet their real estate agents?",
    slug: "how-we-work",
    answers: [
      <p>
        We’re always looking to work with the best agents in a given area,
        whether new to the industry or seasoned veterans. We verify the validity
        of all agents’ licenses, brokerage details, and contact information.
        Only agents whose profiles are complete to a specific degree receive a
        checkmark badge to communicate that their profile’s complete, and not
        every agent regularly receives referrals from us.
      </p>,
    ],
  },
  {
    question: "How does RealEstateAgents.com make money?",
    slug: "how-we-work",
    answers: [
      <p>
        When a client gets connected to an agent, we create what’s called a
        referral. In the real estate industry a “referral” is a very specific
        thing that kicks off a real estate transaction, and for this referral
        the agent who closes a transaction with the client pays the party that
        initiated the referral a fee. That, in addition to other agent related
        services, is how RealEstateAgents.com generates most of its revenue.
      </p>,
    ],
  },
];

export const personalRelocationNetworkSlides = [
  {
    title: "Send Referrals",
    image:
      "/static/images/personal-relocation-network/personal-relocation-network-slide-one.min.webp",
    alt: "Send referrals through RealEstateAgents.com",
    text:
      "It’s easy to submit a referral through your RealEstateAgents.com account by providing basic contact & transaction information. Completing the required fields only takes a few minutes.",
  },
  {
    title: "We Do the Matching",
    image:
      "/static/images/personal-relocation-network/personal-relocation-network-slide-two.min.webp",
    alt: "Match your referrals with the best real estate agents",
    text:
      "Through our proprietary, AI-powered matching system, we match your referral with the agents who are most likely to close it. We’ll provide updates to you as the transaction progresses.",
  },
  {
    title: "You Get Paid",
    image:
      "/static/images/personal-relocation-network/personal-relocation-network-slide-three.min.webp",
    alt: "Get paid closing referrals",
    text:
      "Once a closing has occurred, RealEstateAgents.com manages all payment collection and disbursement to you. Sit back and relax while we do the heavy lifting.",
  },
];

export const asSeenInImageMap = [
  {
    href:
      "https://www.nar.realtor/realtor-benefits-program/business-marketing/realestateagents-com-by-referralexchange",
    image: "/static/images/nar/nar-logo.2x.min.webp",
    alt: "National Association of REALTORS logo",
  },
  {
    href: "https://www.housingwire.com/company-profile/referralexchange/",
    image: "/static/images/logos/housing-wire-logo.webp",
    alt: "HOUSINGWIRE logo",
  },
  {
    href: "https://www.rismedia.com/newsmaker/daniel-yarlett-2023/",
    image: "/static/images/logos/rismedia-logo.webp",
    alt: "RisMedia logo",
  },
  {
    href:
      "https://www.inman.com/2020/08/04/referralexchange-joins-nar-realtor-benefits-program/",
    image: "/static/images/logos/inman-logo.webp",
    alt: "Inman logo",
  },
  {
    href:
      "https://www.forbes.com/sites/forbesrealestatecouncil/2019/04/16/real-estate-agents-heres-how-to-spin-your-time-into-gold-not-straw/?sh=5f7ce3fd37a0",
    image: "/static/images/logos/forbes-logo.webp",
    alt: "Forbes logo",
  },
  {
    href: "https://www.inc.com/profile/referralexchange",
    image: "/static/images/logos/inc-5000-logo.webp",
    alt: "Inc 5000 logo",
  },
];

export const availableNarrativeImagesBySlug = [
  {
    city: "san-francisco",
    state: "ca",
    altText: "San Francisco, CA Painted Ladies Skyline",
  },
  { city: "atlanta", state: "ga", altText: "Atlanta, GA Skyline" },
  { city: "dallas", state: "tx", altText: "Dallas, TX Skyline" },
  { city: "las-vegas", state: "nv", altText: "Las Vegas, NV Sign" },
  { city: "houston", state: "tx", altText: "Houston, TX Skyline Root Square" },
  { city: "los-angeles", state: "ca", altText: "Los Angeles, CA Skyline" },
  { city: "austin", state: "tx", altText: "Austin, TX Skyline" },
  { city: "seattle", state: "wa", altText: "Seattle, WA Skyline" },
  { city: "miami", state: "fl", altText: "Miami, FL Skyline" },
  { city: "orlando", state: "fl", altText: "Orlando, FL Lake Eola Park" },
];

export const MIN_PRICE = 100000;
export const MAX_PRICE = 5000000;
export const DEFAULT_BOUNDS_DISTANCE = 8046.72; //units in meters === 5 Miles

export const REX_CONSENT_TEXT = `By checking the box and clicking “Accept and Continue” below, I agree to receive automated marketing calls and texts from or on behalf of ReferralExchange regarding its products and services at the phone number provided above. I understand that my consent is not a condition of any purchase, and I may revoke consent at any time. Msg & data rates may apply; frequency varies.`;
