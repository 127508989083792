import { useEffect, useState } from "react";
import Error from "next/error";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import "isomorphic-unfetch";
import TagManager from "react-gtm-module";
import Head from "../components/Meta/Head";

import { createUUID } from "../utils/UUID";
import { calculateBreadcrumbSchema } from "../utils/Breadcrumbs";

import DefaultLayout from "~/components/layouts/Default";

const isError = (error) => {
  return error.statusCode < 200 || error.statusCode > 300;
};

const Base = (props) => {
  const { Component, pageProps, router } = props;
  const [hasTouch, setHasTouch] = useState(false);
  const [showPrime, setShowPrime] = useState(false);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (!url.searchParams.has("no-gtag")) {
      TagManager.initialize({
        gtmId: "GTM-PXNTZ86",
      });
    }
    createUUID({});
    setHasTouch(isTouchDevice());

    if (window.location.search) {
      window.localStorage.setItem("loadedQueryParams", window.location.search);
    }
  }, []);

  useEffect(() => {
    setShowPrime(
      router.query.force_experience === "prime" ||
        pageProps?.pageData?.agent?.prime
    );
  }, [router.asPath]);

  if (pageProps && pageProps.statusCode && isError(pageProps)) {
    return (
      <div
        id={"pageWrapper"}
        className={css(
          styles.pageWrapper,
          hasTouch && styles.touchDevice,
          router.pathname.includes("/[agent_slug]") &&
            showPrime &&
            styles.agentPageMobileSticky
        )}
      >
        <Error statusCode={pageProps.statusCode} />
      </div>
    );
  }

  const getLayout =
    Component.getLayout || ((page) => <DefaultLayout>{page}</DefaultLayout>);

  let metadata = { ...pageProps.metadata };

  if (!metadata.breadcrumbJson) {
    let pathnameArray = router.pathname.split("/");
    pathnameArray.shift();
    let pathArray = router.asPath.split("/");
    pathArray.shift();

    if (router.pathname === "/") {
      pathnameArray = [];
      pathArray = [];
    }
    const breadcrumbJson = calculateBreadcrumbSchema({
      pathnameArray,
      pathArray,
      agent: pageProps.pageData?.agent,
      city: pageProps.pageData?.location?.city,
      state: pageProps.pageData?.location?.state,
    });
    metadata = { ...metadata, breadcrumbJson };
  }

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  return (
    <div
      id={"pageWrapper"}
      className={css(
        styles.pageWrapper,
        hasTouch && styles.touchDevice,
        router.pathname.includes("/[agent_slug]") &&
          showPrime &&
          styles.agentPageMobileSticky
      )}
    >
      <div id={"contentContainer"} className={css(styles.contentContainer)}>
        <Head {...metadata} />
        {getLayout(<Component {...pageProps} />)}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  pageWrapper: {
    width: "100%",
    minHeight: "100vh",
    background: "#fff",
  },
  agentPageMobileSticky: {
    "@media only screen and (max-width: 1023px)": {
      paddingBottom: 140,
    },
  },
  touchDevice: {
    "@media only screen and (max-width: 767px)": {
      cursor: "pointer",
    },
  },
  sticky: {
    zIndex: 2,
  },
  stickContainer: {
    zIndex: 10,
    position: "absolute",
    width: "100%",
  },
  contentContainer: {
    position: "relative",
  },
});

export default Base;
