import { StyleSheet, css } from "aphrodite";
import { isMobileOnly } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COLORS } from "~/config/themes";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

const Input = (props) => {
  return (
    <div
      className={css(
        styles.inputContainer,
        props.className,
        props.inputContainerStyles
      )}
    >
      <div className={css(styles.inputGroup, props.inputGroup)}>
        {props.icon ? (
          <div className={css(styles.iconContainer, props.iconStyles)}>
            {props.icon}
          </div>
        ) : props.noIcon ? null : props.fontAwesomeIcon ? (
          <div className={css(styles.iconContainer)}>
            <FontAwesomeIcon
              icon={props.fontAwesomeIcon}
              color={props.iconColor || COLORS.BLACK()}
              size={props.iconSize || "1x"}
            />
          </div>
        ) : null}
        <input
          className={css(styles.input, props.inputStyle, props.className)}
          value={props.value}
          defaultValue={props.defaultValue}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          name={props.name}
          ref={props.forwardedRef}
          autoComplete={props.autoComplete}
          onFocus={props.onFocus}
          onKeyDown={
            props.onKeyDown
              ? (e) => props.onKeyDown(e, props.suggestions, props.handleSelect)
              : null
          }
          onKeyPress={
            props.onKeyPress
              ? (e) =>
                  props.onKeyPress(e, props.suggestions, props.handleSelect)
              : null
          }
          autoFocus={props.autoFocus}
          required={props.required}
          maxLength={props.maxLength}
          minLength={props.minLength}
          id={props.id}
          pattern={props.pattern}
        />
      </div>
      {props.searchButton ? props.searchButton : null}
      {isMobileOnly && props.navSearch && (
        <FontAwesomeIcon
          icon={faTimes}
          color={COLORS.BLACK()}
          onClick={() => props.onCloseClick()}
        />
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  input: {
    width: "100%",
    height: "100%",
    fontSize: 16,
    boxSizing: "border-box",
    outline: "none",
    borderRadius: 2,
    border: "none",
    background: "transparent",

    ":placeholder": {
      opacity: 0.5,
    },
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    width: 460,
    height: 60,
    padding: 16,
    boxSizing: "border-box",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: 10,
    display: "flex",
    alignItems: "center",
  },
});

export default Input;
